import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "../styles/landingPage.css";

const adminPassword = process.env.REACT_APP_ADMIN_PASSWORD;

const LandingPage = () => {
  const navigate = useNavigate();

  const [password, setPassword] = useState("");
  const [isAdmin, setIsAdmin] = useState(false);
  const [showDashboard, setShowDashboard] = useState(false);
  const [showAdminInput, setShowAdminInput] = useState(false);
  const [wrongPassword, setWrongPassword] = useState(false);

  const handleAdminClick = () => {
    setShowAdminInput(true);
  };

  const handleCoachToolsClick = () => {
    navigate("/coach-tools");
  };

  const handleNewCoachToolsClick = () => {
    navigate("/coach-tools-2");
  };

  const handleCloseClick = () => {
    setShowAdminInput(false);
    setPassword("");
    setWrongPassword(false);
  };

  const handlePasswordSubmit = (e) => {
    e.preventDefault();
    if (password === adminPassword) {
      setIsAdmin(true);
      localStorage.setItem("isAdmin", "true"); // set a flag in the local storage
      navigate("/dashboard");
    } else {
      setIsAdmin(false);
      setShowDashboard(false);
      setWrongPassword(true);
    }
    setPassword("");
  };

  return (
    <div>
      {
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "80vh", // take up 80pc viewport height
          }}
        >
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr", // create two columns of equal width
              gap: "10px", // space between grid items
            }}
          >
            <div
              onClick={handleAdminClick}
              className="flex flex-col items-center justify-center p-2 rounded-md backdrop-blur-md bg-white"
            >
              <img
                src="/admin-landing.png"
                alt="admin logo"
                width="100"
                height="auto"
              />
              <br />
              <strong className="text-lg">Admin</strong>
            </div>

            {/* <div
              onClick={handleCoachToolsClick}
              className="flex flex-col items-center justify-center p-2 rounded-md backdrop-blur-md bg-white"
            >
              <img
                src="/coach-landing.png"
                alt="coach logo"
                width="100"
                height="auto"
              />
              <br />
              <strong className="text-lg">Coach Tools</strong>
            </div> */}

            <div
              onClick={handleNewCoachToolsClick}
              className="flex flex-col items-center justify-center p-2 rounded-md backdrop-blur-md bg-white"
            >
              <img
                src="/coach-landing.png"
                alt="coach logo"
                width="100"
                height="auto"
              />
              <br />
              <strong className="text-lg">New Coach Tools</strong>
            </div>
          </div>
        </div>
      }

      {showAdminInput && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <form
            onSubmit={handlePasswordSubmit}
            style={{
              backgroundColor: "lightgray",
              padding: "20px",
              borderRadius: "10px",
              width: "300px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div
              style={{
                border: "1px solid black",
                borderRadius: "5px",
                padding: "5px",
                backgroundColor: "rgba(255, 0, 0, 0.5)",
                cursor: "pointer",
              }}
              onClick={handleCloseClick}
            >
              Close
            </div>
            <h2>Enter password for admin</h2>
            {wrongPassword && (
              <div style={{ color: "red" }}>Wrong password</div>
            )}
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              style={{ margin: "10px 0", padding: "10px" }}
            />
            <button type="submit" disabled={!password}>
              Submit
            </button>
          </form>
        </div>
      )}
    </div>
  );
};

export default LandingPage;
